.maskModal{
    background-color: rgba(0,0,0,.45);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}
.ModalWrap{
    /* bottom: 0;
    left: 0;
    outline: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100; */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.modal{
    box-sizing: border-box; 
    /* max-height: 80vh; */
    /* max-width: 60vh;  */
    background: white;
    display: flex;
    align-items: center;
    transition: 0.5s ease-out;
    position: relative;
    /* top: 100px; */
    margin: 0 auto;
    /* padding: 20px; */
    flex-direction: column;
    border-radius: 5px;
    /* position: fixed;
    top: 30%;
    left: 50%;
    width: 400px;
    height: 300px;
    background:transparent;
    z-index: 100;
    visibility:visible;
    font-feature-settings: "tnum","tnum";
    box-sizing: border-box;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    margin: 0 auto;
    max-width: calc(100vw - 32px);
    padding: 0 0 24px;
    pointer-events: none;
    position: relative;
    top: 100px;
    
    opacity: 1; */
    
    /* transition: 0.5s ease-out; */
  }
  .modalContent{
    padding: 10px;
    /* padding: 20px; */
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .closeBtn{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
  }

  .header{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .header>*:nth-child(1){
    flex: 1 1 5%;
    display: flex;
    align-items: center;
    width: 1.1rem;
  }

  .header>*:nth-child(1) img{
   width: 1rem;
   height: 1rem;
  cursor: pointer;
  }

  .header>*:nth-child(1)>*{
   display: flex;
   align-items: center;
   }

   .header>*:nth-child(3)>*{
    display: flex;
    align-items: center;
    width: 1.1rem;
    }

  .header>*:nth-child(2){
    flex: 1 1 88%;
    display: flex;
    justify-content: center;
  }

  .header>*:nth-child(2) h2{
 font-size: 23px;
 font-weight: 600;
  }



  .header>*:nth-child(3)
  {
    display: flex;
    flex-direction: 1 1 8%;
    align-items: center;
  }
  .header>*:nth-child(3) img{
    width: 1rem;
    height: 1rem;
    cursor: pointer;
   }
 /* .custom-upload-dragger .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  pointer-events: none;
  border:2px solid green
} */