
@media screen and (min-width: 700px) {
        .uploadFiles >*:nth-child(2) >*:nth-child(1){
max-width:150px;
    }
    .DashboardListRowBlog{
    border-top: 1px solid #DBDBDB;
    display: flex;
    width:100%;
    height: 85vh;
    overflow-y: auto;
     background-color: #F5F7FA;
}
.DashboardListRow{
    border-top: 1px solid #DBDBDB;
    display: flex;
    width:100%;
    max-height: 70vh;
    overflow-y: auto;
    background-color: #F5F7FA;
}}
.DashboardListRowfit{
    border-top: 1px solid #DBDBDB;
    display: flex;
    width:100%;
    max-height: 70vh;
    overflow-y: auto;
     background-color: #F5F7FA;
    /* border:2px solid red */
}
.DashboardListRowGallery {
  border-top: 1px solid #dbdbdb;
  display: flex;
  width: 100%;
  max-height: 63vh;
  overflow-y: auto;
   background-color: #F5F7FA;
  align-items: flex-start; 
}
.DashboardListRowGalleryMob {
  /* border-top: 1px solid #dbdbdb; */
  display: flex;
  width: 100%;
  max-height: 64vh;
  overflow-y: auto;
   background-color: #F5F7FA;
  align-items: flex-start; 
  margin-top: 15px;
}

.DashboardMapDiv{
    display: flex;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
    height:12vh;
    width:100%;
    background-color: white;
    box-shadow: 0px 3px 6px #00000017;
    align-items: center;
    /* border:2px solid green */
}
.DashboardMapDivGallery{
    display: flex;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
    height:11vh;
    width:100%;
    background-color: white;
    box-shadow: 0px 3px 6px #00000017;
    /* border:2px solid red */
}
.DashboardMapGalleryMob{
  display: flex;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
    height:15vh;
    width:100%;
    background-color: white;
    box-shadow: 0px 3px 6px #00000017;
}
.MapGalleryMob{
  display: flex;
  flex-direction: column;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 10px;
    height:12vh;
    width:100%;
    background-color: white;
    box-shadow: 0px 3px 6px #00000017;
}
.Heading{
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
     background-color: #F5F7FA;
    /* border:1px solid blue; */
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    height: 6vh;
    align-items: center;
    display: flex;
    justify-items: center;
}
.ImageDiv{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
}

.ImageDivEvents{
    width: 20%;
    height: 45px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    /* border:2px solid green; */
}
.contentDiv{
    /* border:2px solid green; */
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
}
.contentDivEvent{
    /* border:2px solid green; */
    height: 100%;
    width: 70%;
    display: flex;
    align-items: center;
}
.TableColumns{
    width: 22vw;
    height:100%;
    display: flex;
    align-items: center;
    gap:6px;
    margin-right: 10px;
    
}
.HeaderStyle{
    font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif;
}


@media screen and (max-width: 700px) {

    /* YourModalStyles.css */
.modal-overlay {
    top: 13%;
    left: 13%;
  position: fixed;
  top: 0;
  left: 0;
  width: 60%;
  height: 75%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Higher z-index to ensure it's displayed over the content */
}

    .BlogUsersRow{
    border-top: 1px solid #DBDBDB;
    display: flex;
    width:100%;
    height: 89vh;
    overflow-y: auto;
     background-color: #F5F7FA;
}
    .DashboardListRowBlog{
    border-top: 1px solid #DBDBDB;
    display: flex;
    width:100%;
    height: 67vh;
    overflow-y: auto;
     background-color: #F5F7FA;
}
      .DashboardDivMobileLoc{
        width:99%;
        height: 35vh;
        /* border:2px solid green; */
        margin-bottom: 5px;
        box-shadow:0px 3px 6px 0px #00000017 ;
        padding:12px;
        border-radius: 5px;
        background-color: white;
    }
    .DashboardDivMobile{
        width:99%;
        height: 30vh;
        margin-bottom: 8px;
        box-shadow:0px 3px 6px 0px #00000017 ;
        padding:12px;
        border-radius: 5px;
        background-color: white;
    }
    .DashboardDivMobileBlog{
        width:100%;
        height: 40vh;
        /* border:2px solid green; */
        margin-bottom: 5px;
        box-shadow:0px 3px 6px 0px #00000017 ;
        padding:12px;
        border-radius: 5px;
        background-color: white;  
    }
      .VideoDivMobile{
        width:99%;
        height: 25vh;
        margin-bottom: 5px;
        box-shadow:0px 3px 6px 0px #00000017 ;
        padding:12px;
        border-radius: 5px;
        background-color: white;
    }
    .GalleryDivMobile{
        width:99%;
        height: 20vh;
        /* border:2px solid green; */
        margin-bottom: 5px;
        box-shadow:0px 3px 6px 0px #00000017 ;
        padding:12px;
        border-radius: 5px;
        background-color: white;  
    }
    .MagDivMobile{
       width:99%;
        height: 27vh;
        /* margin-bottom: 8px; */
        /* box-shadow:0px 3px 6px 0px #00000017 ; */
        padding:12px;
        border-radius: 5px;
        background-color: white;  
        border-bottom: 1px solid #DBDBDB;
    }
    .DashboardListRow{
       display: flex;
    width: 98vw;
    height: 80vh;
    background-color: #F5F7FA;
    /* border: 2px solid blue; */
    /* margin-left: -5px; */

}
}