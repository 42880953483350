.sidebar{
    display:flex;
    flex-direction: row;
    gap:12px;
}


.footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    width:100% ;
    /* top: 5vh; */
    height: 20%;
    /* bottom: 10px; */
    /* top: 50px; */
    border-right: 1px solid #e8e6e6;
    gap: 5;
}
@media screen and (max-width:700px) {
.selectedImage {
  filter: brightness(1.5); /* You can adjust this to get the desired blue color effect */
color:red  
}



.selectedMenuItem {
    background-color: #E6F3FF; 
    color:#0086FF;
    height: 30px;
    display: flex;
    align-items: center;
}

    .logo{
    display: flex;
    /* margin-top: 5px; */
    /* justify-content: center;
    align-items: center; */
   height: 50px;
   width:30px;
   /* padding-left:8px; */
   align-items: center;
   /* margin-left: 2vw; */
}
  .menu{
      display: flex;
      align-items: center;
      margin-left: 10px;
      background-color: white;
}  
.sideBar{
    background-color: white;
}
/* .footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: fixed;
    width: 180px;
    bottom: 20px;
    gap: 5;
    background-color: white;
} */
}
@media screen and (min-width:700px) {
    .sidebarheading{
/* font-family: 'Open Sans', sans-serif;
font-family: 'Poppins', sans-serif; */
font-weight:500;
}

    .logo{
    display: flex;
    justify-content: center;
    align-items: center;
   height: 80px;
   width:60px;
   padding-left:8px;
   align-items: center;
   margin-left: 2vw;
}
}
@media screen and (max-width:700px) {
    .sidebarheading{
font-weight:500;
}
}