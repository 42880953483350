.loginContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    width: 100%;
}
.loginMain{
display: flex;
flex-direction: column;
gap: 16px;
padding: 15vh 10vw;
width: 70%;

}
.loginMain img{
    height: 5vh;
    width: 5vh;
}
.loginMain > *:last-child >*:last-child{
    color: #A17AFF;
    cursor: pointer;
}
.loginMain > *:last-child{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.loginOptionDiv{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.loginOptionDiv > *:nth-child(2){
    color:#A17AFF;
    cursor: pointer;
    
}
.signInBtn{
    background-color: #A17AFF;
    border-radius: 4px;
    color: #ffffff;
    margin-top: 16px;
}

.loginBlogMain{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color:white;
    padding: 60px 110px 40px 100px;
    width: 80%;
    height: 100%;
    border-radius: 10px;
  box-shadow: -12px -8px 21px #00000014;;
}
.loginBlogMain>:nth-child(1){
    display: flex;
    justify-content: flex-start;
    width: 100%;
}
.loginBlogMain>:nth-child(1) div{
    font: normal normal bold 60px/82px Open Sans;
    color: #A17AFF;
    margin-bottom: -5vh;
}
.loginBlogMain img{
    width: 32vw;
    height: 50vh;
}
.poweredBy {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 3vh;
}
.poweredBy>*:nth-child(1){
    font: normal normal normal 16px/22px Open Sans;
    color: #000000;
}
.poweredBy >*:nth-child(2){
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.poweredBy >*:nth-child(2) img{
    width: 24px;
    height: 24px;
}
.poweredBy >*:nth-child(2) div{
    font: normal normal bold 16px/22px Open Sans;
    color: #0086FF;
}
@media screen and (min-width: 700px) {
.motivatioLine{
    /* display: flex; */
    /* flex-direction: column; */
    /* gap: 6px; */
    color: #A17AFF;
    /* border:2px solid green */
    /* justify-content: center; */
    /* align-items: center; */
    /* margin-top: 5vh; */
}}