
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;1,400&display=swap');

body{
    font-family: Open Sans;
}

.parent-row12ff{
  position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    }
.btnIconStyle{
display: flex;
flex-direction: row;
gap: 5px;
align-items: center;
}
.blog-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height:5vh;
  align-items: center;
  width: 100%;
}


.galleryModal>*:nth-child(2){
  width: 100%;
  height: 60vh;
}

.UploadDragger-formItem>*:nth-child(1)>*:nth-child(1)>*:nth-child(1)>*:nth-child(1)>*:nth-child(1)>*:nth-child(2){
  max-height: 27vh;
    overflow-y: overlay;
    padding-right: 10px;
}

.UploadDragger{
  border: none !important;
    background: none !important;
}
.galleryUploadDrager{
height: 20vh;
background-color:#eee7ff;
color: #0086FF17;
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border:1px solid #0086FF;
}
.editGallery-container{
  display: flex;
  flex-direction: column;
  gap:20px;
}
.editGallery-containerMob{
   display: flex;
  flex-direction: column;
  gap:20px;
  height: 86vh;
  /* border:2px solid red; */
}
.editGallery-tab-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.editGallery-tab{
  display: flex;
  flex-direction: row;
  gap: 20px;
  font: normal normal 600 18px/24px Open Sans;
  cursor: pointer;
}

.editGallery-tabMob{
    display: flex;
  flex-direction: row;
  gap: 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
border-bottom: 1px solid #DBDBDB;
margin-top: 6px;
}
.editGallery-heading{
  display: flex;
  /* gap:20px; */
  align-items: center;
  
}
.ant-modal-content #delete{
  width: 650px !important;
  /* border: 2px solid; */
}

/* .tableRow{
    background-color: #F5F7FA;
  font: normal normal normal 16px/22px Open Sans;

} */
.ant-table-thead > tr >th{
  font: normal normal normal 16px/22px Open Sans;
}

  .imageInBlog{
    width: 25vw;
    height: 20vh;
  }


  a{
    color: #0086FF;
    text-decoration: underline;
    font-size: 13px;
    font-family: inherit;
  }
  .editBtn{
color: #7D7D7D;
font: normal normal bold 14px/22px Open Sans;
border: none;
background-color: transparent;
box-shadow: none;
cursor: pointer;
display: flex;
gap:5px;
align-items: center;

  }
  .deleteBtn{
color: #FF6969;
font: normal normal bold 14px/22px Open Sans;
border: none;
background-color: transparent;
box-shadow: none;
cursor: pointer;
display: flex;
gap:5px;
align-items: center;
  }
  .deleteButton{
    color:white;
    font: normal normal bold 16px/22px Open Sans;
    font-size: 12px !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .divider{
    width: 0px !important;

/* UI Properties */
border-left: 2px solid #CFCFCF;
opacity: 1;
  }
  
  
  .ant-modal-content {
     background: #F5F7FA 0% 0% no-repeat padding-box;
     /* width: 400px; */
     border-radius:5px;
  }

  .modelTitle{
    color:#FF6969 ;
    font: normal normal normal 24px/33px Open Sans;
    display: flex;
    justify-content: center;
  }
  .deleteModel{
width: 680px;
  }

  .modelInfo{
    color: #2E2E2E;
    font:  normal normal normal 14px/22px Open Sans;
    /* font:normal normal normal 14px/22px Poppins; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .modelBtn{
    display: flex;
   justify-content: center;
  gap: 40px;
  margin: 20px -5px;
  }

  .modalBtnblog {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 20px -5px;
}
  .ant-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    
}
.modelCancelBtn{
  width: 114px;
height: 32px;
justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
/* UI Properties */
border: 1px solid #0086FF;
border-radius: 4px;
opacity: 1;
}
.modelCancelBtnGallery{
    width: 114px;
height: 32px;
justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
/* UI Properties */
border: 1px solid #0086FF;
border-radius: 4px;
opacity: 1;
/* color: #FF6969; */
}
.modelDeleteBtn{
  width: 114px;
height: 32px;
cursor: pointer;
justify-content: center;
    align-items: center;
    display: flex;
/* UI Properties */
background: #FF6969 0% 0% no-repeat padding-box;
border-radius: 4px;
opacity: 1;
color: #FFFFFF;
}
.modelSubmitBtn{
  width: 114px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
      align-items: center;
      display: flex;
  /* UI Properties */
  background:#0086FF;
  border-radius: 4px;
  opacity: 1;
  color: #FFFFFF;
}
.buttonopen{
  width: 114px;
  height: 32px;
  cursor: pointer;
  /* background: #0086FF; */
   background: #0086FF;
  justify-content: center;
      align-items: center;
      display: flex;
  /* UI Properties */
  border-radius: 4px;
  opacity: 1;
  color: #FFFFFF;
}
.button{
  width: 114px;
  height: 32px;
  cursor: pointer;
   background: #0086FF;
  justify-content: center;
      align-items: center;
      display: flex;
  border-radius: 4px;
  opacity: 1;
  color: #FFFFFF;
}
.buttonPending{
    width: 114px;
  height: 32px;
  cursor:default;
  background: #0086FF;
  justify-content: center;
      align-items: center;
      display: flex;
  border-radius: 4px;
  opacity: 1;
  color: #FFFFFF;
}
.approvebutton{
  width: 114px;
  height: 32px;
  cursor: pointer;
  background: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border:1px solid #0086FF;
  border-radius: 4px;
  opacity: 1;
  color:  #0086FF;
  box-shadow: 0px 3px 6px #1a191914;
}

.discard{
  background-color: #F5F7FA;
  box-shadow: 0px 3px 6px #00000014;
border: 1px solid #CFCFCF;
color:  #0086FF;
}
.saveAsDraft{
  background-color: #F5F7FA;
  color: #0086FF;
  border: 1px solid  #0086FF;
  box-shadow: 0px 3px 6px #00000014;
}

.ant-upload-list-picture-card .ant-upload-list-item-error, .ant-upload-list-picture .ant-upload-list-item-error{
  border-color: grey;
}
.ant-upload-list-item-name, .ant-upload-list-item-error {
  color: black !important;
}

::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

/* ::-webkit-scrollbar-track {
} */

::-webkit-scrollbar-thumb {
  background-color: #A0A0A0;   /* color of the scroll thumb */

  border-radius: 20px;       /*roundness of the scroll thumb*/
  /* border: 1px solid #b5dbe8;  creates padding around scroll thumb */

}
.ql-editor{
  min-height:50vh;
  height: auto !important;
  /* max-height: 60vh; */
  background-color: white;
}
.ql-container{
  height: 60vh !important;
  overflow: scroll;
}
.ql-snow .ql-tooltip{
   margin-left: 40vw !important;
    margin-top: 0vh !important;
    top: 0vh !important;
    left: auto !important;
}

.VideoUploadbtn{
  border:2px solid green;
}
.Videobtn{
  border:2px solid red
}
.VideoInput{
      height: fit-content;
    width: fit-content;
        z-index: 1;
}


.ant-tooltip-inner{
  display: none;
}
.ant-tooltip-arrow{
  display: none;
}
/* .ant-table-pagination.ant-pagination {
 position: absolute;
    bottom: -45vh;
    left: 65vw;

} */

.TableRow {
    box-sizing: border-box;
    /* border-radius: 5px; */
    height: 72vh!important;
    width:100%;
    border-top:1px solid #DBDBDB;
    /* max-height: 64vh !important; */
    margin-top: 10px;
    /* max-width: 80vw !important; */
    overflow: auto;
    /* box-shadow: 0px 3px 6px #0000002b; */
    background-color: #F8F8F8;
    border-spacing: 0 10px;
    border-collapse: separate;
}

/* .tableColumn {
  border: 2px solid blue;
} */


.tableRow {
  margin-bottom: 10px !important;
  padding: 7px; 
  background-color: white;
}

.TableRowBlogUsers{
    box-sizing: border-box;
    border-radius: 15px;
   height: 90% !important;
    margin-top: 0px;
    width:100%;
    /* max-width: 80vw !important; */
    overflow: auto;

}

  .TableRowUser {
    box-sizing: border-box;
    border-radius: 15px;
    /* height: auto!important; */
    margin-top: 1px;
    width: 100%;
    /* max-width: 80vw !important; */
    overflow: auto;
    margin-bottom: -32px;
     height:95% !important;


}
.ant-upload-list-item-thumbnail{
  pointer-events: none;
}

.ant-upload-list-item-error, .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon, .ant-upload-list-item-error .ant-upload-list-item-name, .ant-upload-list-item-error .ant-upload-text-icon>.anticon {
    color: grey;
}
.custom-title {
  color: red; /* Set the desired color here */
}
div::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
   height: 4px;
}

div::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Set the border radius of the scrollbar thumb */
}

div::-webkit-scrollbar-thumb:hover {
  background-color: #afafaf;; /* Set the color of the scrollbar thumb on hover */
}

.ant-table-header{
  color: black;
}
.ant-table-thead >* :nth-child(1){
color:#696969;
 background-color: #F8F8F8;
}
.ant-table-thead >* :nth-child(2){
color:#696969;
}
.ant-table-thead >* :nth-child(3){
color:#696969;
background-color: #F8F8F8;

}
.ant-table-thead >* :nth-child(4){
color:#696969;
background-color: #F8F8F8;

}
.ant-table-thead >* :nth-child(5){
color:#696969;
background-color: #F8F8F8;

}
.ant-table-thead >* :nth-child(6){
color:#696969;
background-color: #F8F8F8;

}
.ant-table-thead >* :nth-child(7){
color:#696969;
background-color: #F8F8F8;
 /* box-shadow:  0px 3px 6px #00000017; */

}
/* .ant-table-tbody {
    background-color: #F8F8F8;
} */
 /* .ant-table-tbody .ant-table-row:nth-child(n) {
    background-color: #F8F8F8;
  box-shadow: 3px 6px 12px #00000017;
  margin-bottom: 5px;
       border-spacing: 0 10px; 
      border-collapse: separate; 
         border-bottom: solid 1px black !important 
 

 } */
/* .ant-table-row {
  border-spacing: 5px 10px;
  border:2px solid red !important;
}  */

.model ::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  height: 10px;
}

.model ::-webkit-scrollbar-thumb {
  background-color: #cdcdcd; /* Set the color of the scrollbar thumb */
  border-radius: 3px; /* Set the border radius of the scrollbar thumb */
}

.model ::-webkit-scrollbar-thumb:hover {
  background-color: #b5b2b2; /* Set the color of the scrollbar thumb on hover */
}

.ant-table-tbody{
  display:block;
  /* background-color: blue; */
      border-spacing: 5px 10px !important;
      border-collapse: collapse !important;
  /* height: calc(100%-350px); */
  /* overflow-y:scroll ; */
  /* border: 2px solid red; */
}
/* .ant-table-tbody>*:nth-child(2){
  margin: 2rem;
  padding: 2rem;
}
.ant-table-tbody>*:nth-child(3)>*{
  border: 2px solid;
  margin: 2rem;
} */
.ant-table-tbody > tr:nth-child(n) {
    margin: 10vh;
    background-color: #F8F8F8;
    box-shadow: 0px 3px 6px #00000017;
    padding: 5px !important;
}
/* .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 2px;
    border: 2px solid green;
    height:2px
} */
.TableRow.ant-table-tbody{
  background-color: #F8F8F8;
}


/* .ant-table-tbody>tr>td {
    border-bottom: 1px solid #f0f0f0;
    
}
.ant-table-tbody:hover {
    border-bottom: 1px solid #918f8f;  
} */


/* .ant-table-body{
background-color: #F8F8F8;
} */

.ant-table-pagination.ant-pagination {
    margin: 16px 36px;
}

.galleryUploadDrager >* :nth-child(1){
  color:#0086FF;
}
.ant-popover-inner-content {
    padding: 5px 9px;
}


 @media screen and (min-width: 700px) {
  .heading{
  font: normal normal bold 16px/22px Open Sans;
}
  .quill {
  width:80vw;
}
  .buttonP{
  width: 114px;
  height: 32px;
   background: #0086FF;
  justify-content: center;
      align-items: center;
      display: flex;
  /* UI Properties */
  border-radius: 4px;
  opacity: 1;
  color: #FFFFFF;
}
  .BlogPreviewBtn{
display: flex;
flex-direction: row;
gap: 5px;
align-items: center;
background-color: white;
justify-content: center;
padding: 1vh 1.2vw;
border-radius: 4px;
cursor: pointer;
box-shadow: 0px 1px 4px #00000036;
width: 140px;
}
    .parent-row12ff>*:nth-child(1){
    width: 16vw;
  }
   .editBtnMob{
    gap:40px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
    .firstRow{
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex !important;
    justify-content: space-between !important;
  }
      .headlineBlogdetails{
    font-size: 22px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    font-family: sans-serif;
    margin-bottom: 12px;
    margin-top: 5px;
  }
  .headline{
    font-size: 22px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    font-family: sans-serif;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 12px;
    margin-top: 5px;
  }
    .addbtn{
    background-color: #0086FF;
    font: normal normal bold 14px/18px Open Sans;
    color: white;
    border-radius: 5px;
    width: fit-content;
    height: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:18px;
    /* margin-right: 8px; */
    
  }
      .firstRowDash{
 align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex !important;
    justify-content: space-between !important;
    width:100%;

  }
  .container{
   background-color: #F5F7FA;
    width: 100%;
    height: 86vh;
    padding: 10px 30px 50px 30px;
    border-top:1px solid #DBDBDB;
  }
.blog-tabs{
  display: flex;
  flex-direction: row;
  gap: 3vw;
  font: normal normal 500 18px/24px Open Sans;
  cursor: pointer;
  height: 32px;
  margin-left: 10px;
  margin-top: 10px;
}

  }

@media screen and (max-width: 700px) {
    .heading{
  font-family: poppins;
  font-weight: 600;
}
  .Discardpop :hover{
    color:#0086FF
  }
  .Draftpop :hover{
    color:#0086FF
  }
  .quill {
  width:97vw;
}
  .buttonP{
  width: 114px;
  height: 32px;
  justify-content: center;
      align-items: center;
      display: flex;
  /* UI Properties */
  border-radius: 4px;
  opacity: 1;
  color: #FFFFFF;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px;
    /* height:52vh !important; */
    height:40vh !important;
}
  .BlogPreviewBtn{
display: flex;
flex-direction: row;
gap: 5px;
align-items: center;
background-color: white;
justify-content: center;
padding: 1vh 1.2vw;
border-radius: 6px;
cursor: pointer;
box-shadow: 0px 1px 4px #00000036;
width: 140px;
}
    .parent-row12ff>*:nth-child(1){
    width: 14vw;
  }
    .modelBtnMob{
    display: flex;
   justify-content: center;
  gap: 40px;
  margin: 20px 10px;
  width: 100%;
  justify-content: center;
  }
  .editBtnMob{
    /* gap:10px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5vh;
    width:100%;
    /* margin-left: 60px; */
    justify-content: space-evenly;
  }
    .editBtnMobReject{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    width:100vw;
    justify-content: space-evenly;
  }
 .buttondraftcopy{
  max-width:55vw;
  height: 25px;
  cursor: pointer;
    background-color: #F5F7FA;
  box-shadow: 0px 3px 6px #00000014;
border: 1px solid #CFCFCF;
color:  #0086FF;
  justify-content: center;
      align-items: center;
      display: flex;
  border-radius: 4px;
  opacity: 1;
  padding:3px ;
  
 } 

.button {
  width: fit-content;
  padding: 3px;
   height: 25px;
}

.approvebutton{
  width: fit-content;
   padding: 3px;
  height: 25px;
  cursor: pointer;
  background: white;
  justify-content: center;
  align-items: center;
  display: flex;
  border:1px solid #0086FF;
  border-radius: 4px;
  opacity: 1;
  color:  #0086FF;
  box-shadow: 0px 3px 6px #00000014;
}
    .headlineMob{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 12px;
    margin-top: -10px;
  }
    .firstRow{
    align-items: center;
    padding-bottom: 0px;
    padding-top: 1px;
    display: flex !important;
    justify-content: space-between !important;
    height: 18vh;
    flex-direction: column;
  }
  .blog-tabs{
  display: flex;
  flex-direction: row;
  gap: 4vw;
  cursor: pointer;
  height: 32px;
  margin-left: 10px;
  margin-top: 10px;
  font-weight: 600;
  font-size: 15px;
}
   .headlineBlogdetails{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap:6px;
    font-family: sans-serif;
    margin-bottom: 12px;
    margin-top: 5px;
    height: 60%;
    margin-left: 3px;

  }
  .headline{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 12px;
    margin-top: 5px;
font-family: poppins;
  }
  .headlineM{
        font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    margin-bottom: 12px;
    margin-top: 5px;
font-family: poppins;
  }
  .addbtn{
    background-color: #0086FF;
    font: normal normal bold 14px/18px Open Sans;
    color: white;
    border-radius: 5px;
    width:fit-content;
    height: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:18px;    
  }
   .addbtnblog{
    background-color: #0086FF;
    font-size: 12px;
    color: white;
    border-radius: 4px;
    width:32vw;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
padding:4vw 5vw; 
    font-weight: 500;  
     }
  .addnewbtn{
    background-color: #0086FF;
    font: normal normal bold 14px/18px Open Sans;
    color: white;
    border-radius: 5px;
    width:100%;
    height: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:4vw 6vw;    
  }
    .addnewbtndash{
    background-color: #0086FF;
    font: normal normal bold 14px/18px Open Sans;
    color: white;
    border-radius: 4px;
    width:30vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:4vw 5vw;    
  }
   .addnewbtngallery{
    background-color: #0086FF;
    font: normal normal bold 12px/16px Open Sans;
    color: white;
    border-radius: 4px;
    width: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4vw 5vw;
   }
   /* .deleteselectbtngallery{
    background-color: #FF6969;
    font: normal normal bold 12px/16px Open Sans;
    color: white;
    border-radius: 4px;
    width: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 4vw 5vw;
   } */
  .addnewbtnmag{
     background-color: #0086FF;
    font: normal normal bold 14px/18px Open Sans;
    color: white;
    border-radius: 4px;
    width:40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:4vw 5vw;
  }
    .firstRowDash{
    align-items: center;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex !important;
    justify-content: space-between !important;
    width:100%;
    margin-bottom: 10px;

  }
   .container{
   background-color: #F5F7FA;
    width: 100vw;
    height: 92vh;
    /* padding: 10px 30px 50px 30px; */
    padding: 0px 10px 10px 10px;
        border-top:1px solid #DBDBDB;
    /* border:2px solid green */
  }
   .containerMob{
   background-color: #F5F7FA;
    width: 100%;
    height: 92vh;
    /* padding: 10px 30px 50px 30px; */
     padding: 10px 10px 10px 10px;
    border-top:1px solid #DBDBDB;
    /* border:2px solid blue */
  }
 .containerusers{
   background-color: #F5F7FA;
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* padding: 10px 30px 50px 30px; */
     padding: 10px 10px 10px 10px;
    border-top:1px solid #DBDBDB;
  }}